<template>
  <div>
    <ul class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('BUYER_INFO.TITLE')}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title">
        <h1>{{$t('BUYER_INFO.TITLE')}}</h1>
        <p>{{$t('BUYER_INFO.DESCRIPTION')}}</p>
      </div>
      <div class="card mt-4 text-right" v-if="$store.state.user.status.openSupplier">
        <div class="card-body">
          {{$t('BUYER_INFO.AVAILABLE_COMPANIES_NOTICE', { count: 0 })}}
          <router-link class="btn btn-primary" to="/supplier/manage/buyer-info/add">
            <font-awesome-icon icon="plus"></font-awesome-icon>
            {{$t('BUYER_INFO.ADD_COMPANY')}}
          </router-link>
        </div>
      </div>
      <div class="card biz-primary mt-2">
        <div class="card-header">
          {{$t('BUYER_INFO.HEADER_TRADING')}}
        </div>
        <div class="card-body">
          <div class="p-2 bg-white">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">{{$t('BUYER_INFO.BUYER_NAME')}}</th>
                  <th class="text-center">{{$t('BUYER_INFO.TRADING_TYPE')}}</th>
                  <th class="text-center">{{$t('BUYER_INFO.TRADING_COUNT')}}</th>
                  <th class="text-center">{{$t('BUYER_INFO.TRADING_AMOUNT')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="activeRelations.length <= 0">
                  <td colspan="4">{{$t('BUYER_INFO.NO_TRADING_COMPANY')}}</td>
                </tr>
                <tr v-bind:key="relation.id" v-for="(relation) in activeRelations">
                  <td>{{relation.buyerCompanyGroupName || $t('COMMON.NOT_REGISTERED')}}</td>
                  <td class="text-center">{{relation.ownerSupplier ? $t('BUYER_INFO.DIRECT_TRADING') : $t('BUYER_INFO.OPEN_TRADING')}}</td>
                  <td class="text-right"><a href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/All?' + singleSignOnParam + '&buyerCompanyGroupDisplayCode='+relation.buyerCode)">{{relation.transactionQuantity | formatCurrency}}件</a></td>
                  <td class="text-right"><a href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/All?' + singleSignOnParam + '&buyerCompanyGroupDisplayCode='+relation.buyerCode)">{{relation.amount | formatCapitalCurrencyView}}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card biz-primary mt-4">
        <div class="card-header">
          {{$t('BUYER_INFO.HEADER_WAITING_FOR_CONTRACT')}}
          <p>{{$t('BUYER_INFO.HEADER_WAITING_FOR_CONTRACT_DESC')}}</p>
        </div>
        <div class="card-body">
          <div class="p-2 bg-white">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">{{$t('BUYER_INFO.BUYER_NAME')}}</th>
                  <th class="text-center">{{$t('BUYER_INFO.TRADING_TYPE')}}</th>
                  <th class="text-center">{{$t('BUYER_INFO.CATEGORY_WITH_CONTRACT')}}</th>
                  <th class="text-center">{{$t('BUYER_INFO.TRADING_CONTRACT')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="waitingRelations.length <= 0">
                  <td colspan="4">{{$t('BUYER_INFO.NO_WAITING_COMPANY')}}</td>
                </tr>
                <tr v-bind:key="relation.id" v-for="(relation) in waitingRelations">
                  <td>{{relation.buyerCompanyGroupName || $t('COMMON.NOT_REGISTERED')}}</td>
                  <td class="text-center">{{relation.ownerSupplier ? $t('BUYER_INFO.DIRECT_TRADING') : $t('BUYER_INFO.OPEN_TRADING')}}</td>
                  <td class="text-left">
                    <p v-if="relation.contracts.length <= 0" class="mb-0 text-info">{{$t('BUYER_INFO.WATING_FOR_CONTRACT')}}</p>
                    <p
                      v-for="(contract, contractIndex) in relation.contracts" 
                      v-bind:key="contractIndex"
                      :class="contract.contractSign.signStatus == 1 ? 'mb-0':'mb-0 text-danger'">
                      <span 
                        v-for="(category, categoryIndex) in contract.relatedCategories" 
                        v-bind:key="categoryIndex"
                        :class="categoryIndex > 0 ? 'ml-2':''">
                        {{categoryIndex > 0 ? '>' : ''}}
                        {{category.categoryName}}
                      </span>
                    </p>
                  </td>
                  <td class="text-center">
                    <a href="#" class="btn btn-sm btn-light">{{$t('BUYER_INFO.CANCEL_CONTRACT')}}</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="text-right">
              <span class="text-danger mr-5">{{$t('BUYER_INFO.RED_UNCONCLUDED')}}</span>
              <span v-if="hasRole('SB20')">
                <span v-html="$t('BUYER_INFO.GO_TO_CONTRACT', { link: '/supplier/manage/contract-info'})"></span>
                <!-- 特約への署名は<router-link to="/supplier/manage/contract-info">こちら</router-link>で行ってください。   -->
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <j-button variant="light" to="/supplier/manage">
          <font-awesome-icon icon="arrow-left"></font-awesome-icon>
          {{$t('COMMON.GO_BACK')}}
        </j-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
<script>

  import CompanyInfo from '@/model/CompanyInfo';
  import { mapGetters } from 'vuex';

  export default {
    props: ["isRegistrationWizard"],
    components: {  },
    data: function(){
      return {
        j2Url: process.env.VUE_APP_J2_URL,
        jti: this.$store.state.user.jtiToken,
        activeRelations: [],
        waitingRelations: [],
        appliedRelations: [],
        ohHoldRelations: []
      }
    },
    mounted: function(){
      CompanyInfo.getCompanyRelations(this.$store.state.user.companyCode).then(result => {
        this.activeRelations = result.data.filter(r => r.status === 'IS_ACTIVE');
        this.waitingRelations = result.data.filter(r => r.status === 'IS_WAITING');
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    methods:{
      onSave(){
        this.$emit('onSave', 'CategoryInfo');
      },
      hasRole(roleCode){
        if(!this.$store.state.logged) return false;
        for(var i=0; i < this.$store.state.user.roles.length; i++){
          if(this.$store.state.user.roles[i].roleCode === roleCode){
            return true;
          }
        }
        return false;
      },
      redirectSsoLink(ssoLink, isCurrentTab = true) {
        if (!ssoLink) {
          return;
        }
        this.$store.dispatch('redirectSingleSignOnLink', {ssoLink, isCurrentTab})
      }
    },
    computed: {
      ...mapGetters(["singleSignOnParam"])
    }
  }
</script>

